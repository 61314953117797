import Vue from "vue";
import "@/plugins/axios";
import App from "@/App.vue";
import "@/registerServiceWorker";
import router, { routerTypes } from "@/router";
import store from "@/store";
import vuetify from "@/plugins/vuetify";
import i18n from "@/plugins/i18n";
import "@/plugins/vee-validate";
import "@/plugins/google-maps";
import { textFilter } from "@/utils/common";
import { localize } from "vee-validate";
import VCalendar from "v-calendar";
import moment from "moment";

Vue.use(VCalendar, {
  componentPrefix: "vc",
});

Vue.config.productionTip = false;

Vue.mixin({
  data: () => {
    return {
      routerTypes: routerTypes,
    };
  },
  methods: {
    formatText: textFilter,
  },
});

localize(i18n.locale);
moment.locale(i18n.locale);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
