

























































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { localize } from "vee-validate";
import PageFooter from "@/components/PageFooter.vue";
import moment from "moment";
import i18n from "@/plugins/i18n";

@Component({
  components: { PageFooter },
})
export default class App extends Vue {
  switchLocale(locale: string): void {
    this.$i18n.locale = locale;
    localize(locale);
    moment.locale(i18n.locale);
  }
}
