import anchorme from "anchorme";
import VueI18n from "vue-i18n";
import TranslateResult = VueI18n.TranslateResult;

const ESC: any = {
  "<": "&lt;",
  ">": "&gt;",
  '"': "&quot;",
  "&": "&amp;",
};

const escapeChar = (a: any) => {
  return ESC[a] || a;
};

const anchormeOptions = {
  attributes: {
    target: "_blank",
  },
};

export const textFilter = (value: string | TranslateResult): string => {
  if (value) {
    value = value.toString();
    value = value.replace(/[<>"&]/g, escapeChar);
    value = anchorme({ input: value, options: anchormeOptions });
    return value.replace(/\n/g, "<br />");
  } else {
    return "";
  }
};
