import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import IconInstagram from "@/components/IconInstagram.vue";
import FlagCz from "@/components/FlagCz.vue";
import FlagEn from "@/components/FlagEn.vue";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "md",
    values: {
      instagram: {
        component: IconInstagram,
      },
      flag_cz: {
        component: FlagCz,
      },
      flag_en: {
        component: FlagEn,
      },
    },
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#2980B9",
        primary_light: "#E4EFF6",
        secondary: "#3B3B3B",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#A8C91E",
        warning: "#FFC107",
      },
    },
  },
});
