import Vue from "vue";
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  localize,
} from "vee-validate";
import { required, email, max } from "vee-validate/dist/rules";
import en from "vee-validate/dist/locale/en.json";
import cs from "vee-validate/dist/locale/cs.json";

extend("required", required);
extend("email", email);
extend("max", max);
extend("phone", (value: string) => {
  const strongRegex = new RegExp("^(([0+]+)[0-9]\\d*)$");
  return strongRegex.test(value);
});

localize({
  en,
  cs,
});

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
