import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

export const routerTypes = {
  HOME: "home",
  ACCOMMODATION: "accommodation",
  RESERVATION: "reservation",
};

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: routerTypes.HOME,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Home.vue"),
  },
  {
    path: "/accommodation",
    name: routerTypes.ACCOMMODATION,
    component: () =>
      import(
        /* webpackChunkName: "accommodation" */ "../views/Accommodation.vue"
      ),
  },
  {
    path: "/reservation",
    name: routerTypes.RESERVATION,
    component: () =>
      import(/* webpackChunkName: "reservation" */ "../views/Reservation.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // scrollBehavior(to, from, savedPosition) {
  //   if (to.hash) {
  //     return {
  //       selector: to.hash,
  //       offset: { x: 0, y: 100 },
  //       behavior: "smooth",
  //     };
  //   }
  // },
});

export default router;
